/* social icons */
@font-face {
    font-family: 'social_icons';
    src:url('../fonts/social_icons.eot');
    src:url('../fonts/social_icons.eot') format('embedded-opentype'),
        url('../fonts/social_icons.woff') format('woff'),
        url('../fonts/social_icons.ttf') format('truetype'),
        url('../fonts/social_icons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'social_icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-evernote:before {
    content: "\e004";
}
.icon-feedly:before {
    content: "\e007";
}
.icon-pocket:before {
    content: "\e008";
}
.icon-line:before {
    content: "\e009";
}
.icon-hatena:before {
    content: "\e00a";
}
.icon-feedly-square:before {
    content: "\e601";
}
.icon-googleplus:before {
    content: "\e608";
}
.icon-facebook:before {
    content: "\e60d";
}
.icon-instagram:before {
    content: "\e610";
}
.icon-twitter:before {
    content: "\e611";
}
.icon-feed:before {
    content: "\e614";
}
.icon-youtube:before {
    content: "\e617";
}
.icon-flickr2:before {
    content: "\e61e";
}
.icon-githubmark:before {
    content: "\e626";
}
.icon-github:before {
    content: "\e627";
}
.icon-wordpress:before {
    content: "\e629";
}
.icon-tumblr:before {
    content: "\e62d";
}
.icon-yahoo:before {
    content: "\e62f";
}
.icon-apple:before {
    content: "\e631";
}
.icon-android:before {
    content: "\e633";
}
.icon-windows:before {
    content: "\e634";
}
.icon-windows8:before {
    content: "\e635";
}
.icon-skype:before {
    content: "\e636";
}
.icon-delicious:before {
    content: "\e638";
}
.icon-pinterest:before {
    content: "\e63a";
}
.icon-pushpin:before {
    content: "\e900";
}