/* social icons */
@font-face {
  font-family: 'social_icons';
  src: url("../fonts/social_icons.eot");
  src: url("../fonts/social_icons.eot") format("embedded-opentype"), url("../fonts/social_icons.woff") format("woff"), url("../fonts/social_icons.ttf") format("truetype"), url("../fonts/social_icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'social_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-evernote:before {
  content: "\e004";
}

.icon-feedly:before {
  content: "\e007";
}

.icon-pocket:before {
  content: "\e008";
}

.icon-line:before {
  content: "\e009";
}

.icon-hatena:before {
  content: "\e00a";
}

.icon-feedly-square:before {
  content: "\e601";
}

.icon-googleplus:before {
  content: "\e608";
}

.icon-facebook:before {
  content: "\e60d";
}

.icon-instagram:before {
  content: "\e610";
}

.icon-twitter:before {
  content: "\e611";
}

.icon-feed:before {
  content: "\e614";
}

.icon-youtube:before {
  content: "\e617";
}

.icon-flickr2:before {
  content: "\e61e";
}

.icon-githubmark:before {
  content: "\e626";
}

.icon-github:before {
  content: "\e627";
}

.icon-wordpress:before {
  content: "\e629";
}

.icon-tumblr:before {
  content: "\e62d";
}

.icon-yahoo:before {
  content: "\e62f";
}

.icon-apple:before {
  content: "\e631";
}

.icon-android:before {
  content: "\e633";
}

.icon-windows:before {
  content: "\e634";
}

.icon-windows8:before {
  content: "\e635";
}

.icon-skype:before {
  content: "\e636";
}

.icon-delicious:before {
  content: "\e638";
}

.icon-pinterest:before {
  content: "\e63a";
}

.icon-pushpin:before {
  content: "\e900";
}

body {
  padding: 0;
  margin: 0;
  background: #000;
  color: #fff;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
}

.container > section {
  margin-bottom: 3em;
}

section > section {
  padding: 1em;
}

section h3 {
  padding: 0.5em 0 0;
  border-top: 1px dotted #5E4743;
  text-shadow: 0px 0px 10px red, 0px 0px 10px red;
}

section > h3 {
  font-size: 20px;
  overflow: hidden;
}

p {
  font-size: 1.1em;
  line-height: 1.5;
}

.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}

a.twitter-link {
  color: #fff !important;
}

.team-entry {
  display: none;
}

.team-entry textarea {
  resize: none;
}

.team-entry label {
  font-weight: normal;
  white-space: nowrap;
}

.team-entry option:not([value]) {
  color: #9B9B9B;
}

.team-entry .team-entry-form .team-member {
  display: block;
  overflow: hidden;
  margin: 20px 0;
}

.team-entry .team-entry-form legend {
  font-size: 16px;
  font-weight: bold;
  padding: 0 0 0.2em 5px;
}

.team-entry .team-entry-form .form-member {
  clear: both;
  overflow: hidden;
  margin-bottom: 1em;
}

.team-entry .team-entry-form .form-horizontal .form-group {
  margin-right: 0px;
  margin-left: 0px;
}

.team-entry #message {
  display: none;
}

.team-entry #message .btn {
  margin-left: 1em;
}

.team-entry .entry-explain {
  margin-right: 10px;
  margin-bottom: 1em;
}

.team-entry .entry-explain ul {
  margin-top: 0.8em;
  margin-left: 0.8em;
}

.team-entry .require {
  border: 1px solid #A94442;
}

.team-entry .form-group .control-label {
  padding-left: 5px;
  text-align: left;
  color: #000 !important;
}

.team-entry .form-group .control-label .badge {
  font-size: 10px;
  background: #A94442;
}

.team-entry .form-group .control-label[for=pass] {
  padding-top: 0;
}

.team-entry .after .radio-inline {
  padding-top: 14px;
}

.edit-form {
  margin: 0 15px 15px;
}

.edit-form #target {
  max-width: 180px;
  margin-right: 1em;
}

.edit-form #message2 {
  display: none;
  margin-top: 1em;
}

#entry-button {
  min-width: 60px;
}

#edit-button {
  display: none;
}

#delete-button {
  display: none;
}

.entry-open, .entry-close {
  display: none;
}

/* entry-form */
.team-entry .visible-entry {
  display: block;
}

.team-entry .visible-entry-inline-block {
  display: inline-block;
}

.team-entry .visible-edit {
  display: none;
}

.team-entry .visible-edit-inline-block {
  display: none;
}

.team-entry.edit .visible-entry {
  display: none;
}

.team-entry.edit .visible-entry-inline-block {
  display: none;
}

.team-entry.edit .visible-edit {
  display: block;
}

.team-entry.edit .visible-edit-inline-block {
  display: inline-block;
}

.modal-dialog .modal-header {
  background: #000;
  color: #fff;
}

.modal-dialog .modal-footer {
  text-align: center;
}

.modal-dialog .modal-footer button {
  margin-left: 1em;
  min-width: 100px;
}

.modal-dialog .modal-footer button:first-child {
  margin-left: 0;
}

.modal-dialog .modal-content {
  color: #000;
}

.modal-dialog .modal-body {
  padding: 15px 15px 0;
}

.modal-dialog .close {
  color: #fff;
  opacity: 0.5;
}

/* for webkit bug */
.modal-open {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.done {
  background: rgba(222, 110, 110, 0.2);
}

.animate {
  transition: background-color linear 10s;
  background-color: transparent;
}

.entry-summary {
  margin-bottom: 1em;
}

.entry-summary dl {
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
}

.entry-summary dt {
  flex: 1 1 240px;
  font-weight: normal;
}

.entry-summary dd {
  flex: 1 1 calc(100% - 240px);
  font-weight: bold;
  text-align: right;
}

#entries > h3, #entries-single > h3, .entry-summary > h3 {
  font-weight: bold;
  border: none;
  font-size: 24px;
  margin-bottom: 1em;
}

.updated-at {
  color: white;
  text-shadow: none;
  line-height: 22px;
}

.team .team-name {
  line-height: 1.4;
  padding-left: 5px;
  color: #fff;
  margin: 5px 0;
  font-size: 1.2em;
  font-weight: bold;
  text-shadow: 0px 0px 6px red, 0px 0px 6px red;
}

.team .team-name .team-no {
  display: inline-block;
  color: gray;
  font-size: 0.8em;
  font-weight: bold;
  text-shadow: none;
  min-width: 3em;
}

.members {
  display: flex;
}

.members .member {
  flex: 1 1 20%;
}

.singles {
  display: flex;
}

.singles .member {
  flex: 0 0 25%;
}

.count {
  display: flex;
  flex-wrap: wrap;
}

.count .member {
  flex: 0 0 25%;
}

.members .member, .singles .member, .count .member {
  margin: 0;
  padding: 10px 5px 10px 63px !important;
  overflow: hidden;
}

.members .member img, .singles .member img, .count .member img {
  width: 50px;
  height: 50px;
  border-radius: 2px;
}

.members .member .left-block, .singles .member .left-block, .count .member .left-block {
  float: left;
  margin: 0 0 0 -58px;
}

.members .member .entry-status .material-icons, .singles .member .entry-status .material-icons, .count .member .entry-status .material-icons {
  font-size: 14px;
  color: #999;
}

.members .member .player-name, .singles .member .player-name, .count .member .player-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.members .member .player-chara, .singles .member .player-chara, .count .member .player-chara {
  display: block;
  margin-bottom: 3px;
  font-size: 0.8em;
  color: #FFCC00;
}

.members .member .player-comment, .singles .member .player-comment, .count .member .player-comment {
  font-size: 14px;
  margin-bottom: 0;
}

.members .member .join-party, .singles .member .join-party, .count .member .join-party {
  display: block;
  font-size: 16px;
  padding: 4px 0;
  color: #ccc;
}

.edit-link {
  font-size: 12px;
  color: lightgray !important;
  visibility: hidden;
  text-shadow: none;
}

.edit-link > .material-icons {
  font-size: 14px;
  color: #fff;
  padding: 4px;
  background: #8a2625;
  border-radius: 2px;
}

.edit-link:hover {
  color: #333;
}

.team:hover .edit-link, .single:hover .edit-link {
  visibility: visible;
}

@media (max-width: 768px) {
  .members, .singles {
    flex-wrap: wrap;
  }
  .members .member, .singles .member {
    flex: 0 0 100%;
  }
  .member-blank {
    display: none;
  }
  .count .member {
    flex: 0 0 50%;
  }
  .updated-at {
    display: block;
    float: none !important;
  }
  .edit-link {
    visibility: visible;
  }
  .team-entry .radio-inline + .radio-inline {
    margin-left: 0;
  }
  .team-entry label[for$="-character"] {
    margin-top: 14px;
  }
  .edit-form #target {
    max-width: 100%;
    margin-right: 0;
  }
  .modal-dialog .modal-body {
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal-dialog .modal-footer button {
    min-width: 80px;
    font-size: 11px;
  }
}
