@import 'social_icons';

body {
  padding: 0;
  margin: 0;
  background: #000;
  color: #fff;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
  padding: 0;
}

.container > section {
  margin-bottom: 3em;
}

section > section {
  padding: 1em;
}

section h3 {
  padding: 0.5em 0 0;
  border-top: 1px dotted #5E4743;
  text-shadow: 0px 0px 10px red, 0px 0px 10px red;
}

section > h3 {
  font-size: 20px;
  overflow: hidden;
}

p {
  font-size: 1.1em;
  line-height: 1.5;
}

// bootstrapのスタイルをリセット
.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}
a.twitter-link {
  color: #fff !important;
}

$team-member-count: 5;

// エントリーフォーム
.team-entry {
  display: none;
  textarea {
    resize: none;
  }
  label {
    font-weight: normal;
    white-space: nowrap;
  }
  // select options placeholder
  option:not([value]) {
    color: #9B9B9B;
  }
  .team-entry-form {
    .team-member {
      display: block;
      overflow: hidden;
      margin: 20px 0;
    }
    legend {
      font-size: 16px;
      font-weight: bold;
      padding: 0 0 0.2em 5px;
    }
    .form-member {
      clear: both;
      overflow: hidden;
      margin-bottom: 1em;
    }
    .form-horizontal {
      .form-group {
        margin-right: 0px;
        margin-left: 0px;
      }
    }
  }
  #message {
    display: none;
    .btn {
      margin-left: 1em;
    }
  }
  .entry-explain {
    margin-right: 10px;
    margin-bottom: 1em;
    ul {
      margin-top: 0.8em;
      margin-left: 0.8em;
    }
  }
  .require {
    border: 1px solid #A94442;
  }
  .form-group {
    .control-label {
      padding-left: 5px;
      text-align: left;
      color: #000 !important;
      .badge {
        font-size: 10px;
        background: #A94442;
      }
      &[for=pass] {
        padding-top: 0;
      }
    }
  }
  .after .radio-inline {
    padding-top: 14px;
  }
}

// 編集対象選択フォーム
.edit-form {
  margin: 0 15px 15px;
  #target {
    max-width: 180px;
    margin-right: 1em;
  }
  #message2 {
    display: none;
    margin-top: 1em;
  }
}

// フォームボタン
#entry-button {
  min-width: 60px
}
#edit-button {
  display: none;
}
#delete-button {
  display: none;
}

// visible change entry buttons by status (js)
.entry-open, .entry-close {
  display: none;
}

/* entry-form */
.team-entry {
  // default
  .visible-entry {
    display: block;
  }
  .visible-entry-inline-block {
    display: inline-block;
  }
  .visible-edit {
    display: none;
  }
  .visible-edit-inline-block {
    display: none;
  }
  // edit mode
  &.edit {
    .visible-entry {
      display: none;
    }
    .visible-entry-inline-block {
      display: none;
    }
    .visible-edit {
      display: block;
    }
    .visible-edit-inline-block {
      display: inline-block;
    }
  }
}

// bootstrap modal dialog
.modal-dialog {
  .modal-header {
    background: #000;
    color: #fff;
  }
  .modal-footer {
    text-align: center;
    button {
      margin-left: 1em;
      min-width: 100px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .modal-content {
    color: #000;
  }
  .modal-body {
    padding: 15px 15px 0;
  }
  .close {
    color: #fff;
    opacity: 0.5;
  }
}
/* for webkit bug */
.modal-open {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}


// realtime entry animation
.done {
  background: rgba(#de6e6e, 0.2);
}
.animate {
  transition: background-color linear 10s;
  background-color: transparent;
}

// entry summary
.entry-summary {
  margin-bottom: 1em;
  dl {
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
  }
  dt {
    flex: 1 1 240px;
    font-weight: normal;
  }
  dd {
    flex: 1 1 calc(100% - 240px);
    font-weight: bold;
    text-align: right;
  }
}

#entries, #entries-single, .entry-summary {
  >h3 {
    font-weight: bold;
    border: none;
    font-size: 24px;
    margin-bottom: 1em;
  }
}

// 更新日時
.updated-at {
  color: white;
  text-shadow: none;
  line-height: 22px;
}

// チームエントリー
.team {
  .team-name {
    line-height: 1.4;
    padding-left: 5px;
    color: #fff;
    margin: 5px 0;
    font-size: 1.2em;
    font-weight: bold;
    text-shadow: 0px 0px 6px red, 0px 0px 6px red;
    .team-no {
      display: inline-block;
      color: gray;
      font-size: 0.8em;
      font-weight: bold;
      text-shadow: none;
      min-width: 3em;
    }
  }
}

// プレイヤー
.members {
  display: flex;
  .member {
    flex: 1 1 100% / $team-member-count;
  }
}

// シングルエントリー
.singles {
  display: flex;
  .member {
    flex: 0 0 25%;
  }
}
// キャラ分布
.count {
  display: flex;
  flex-wrap: wrap;
  .member {
    flex: 0 0 25%;
  }
}

// プレイヤー
.members, .singles, .count {
  .member {
    margin: 0;
    padding: 10px 5px 10px 63px !important;
    overflow: hidden;
    img {
      width: 50px;
      height: 50px;
      border-radius: 2px;
    }
    .left-block {
      float: left;
      margin: 0 0 0 -58px;
    }
    .entry-status .material-icons {
      font-size: 14px;
      color: #999;
    }
    .player-name {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }
    .player-chara {
      display: block;
      margin-bottom: 3px;
      font-size: 0.8em;
      color: #FFCC00;
    }
    .player-comment {
      font-size: 14px;
      margin-bottom: 0;
    }
    .join-party {
      display: block;
      font-size: 16px;
      padding: 4px 0;
      color: #ccc;
    }
  }
}

// 各チーム、シングルエントリーにある編集ボタン
.edit-link {
  font-size: 12px;
  color: lightgray !important;
  visibility: hidden;
  text-shadow: none;
  > .material-icons {
    font-size: 14px;
    color: #fff;
    padding: 4px;
    background: #8a2625;
    border-radius: 2px;
  }
  &:hover {
    color: #333;
  }
}
.team:hover, .single:hover {
  .edit-link {
    visibility: visible;
  }
}

// モバイル
@media (max-width: 768px) {
  .members, .singles{
    flex-wrap: wrap;
    .member {
      flex: 0 0 100%;
    }
  }
  .member-blank {
    display: none;
  }
  .count {
    .member {
      flex: 0 0 50%;
    }
  }
  .updated-at {
    display: block;
    float: none !important;
  }
  .edit-link {
    visibility: visible;
  }
  .team-entry {
    .radio-inline+.radio-inline {
      margin-left: 0;
    }
    label[for$="-character"] {
        margin-top: 14px;
    }
  }
  .edit-form {
    #target {
      max-width: 100%;
      margin-right: 0;
    }
  }
  .modal-dialog {
    .modal-body {
      padding-left: 10px;
      padding-right: 10px;
    }
    .modal-footer button {
      min-width: 80px;
      font-size: 11px;
    }
  }
}
